<template>
    <div>
        <div class="mb-3">
            <router-link to="/admin/spots/sites" class="text-decoration-none">
                <b-button type="is-light" size="is-small" icon-left="backward"> Retourner aux spots</b-button>
            </router-link>
        </div>
        <div class="box">
            <h3>{{ spotUrl }}</h3>
            <ContentItemTable :spot-id="spotId" />
        </div>
    </div>
</template>

<script>
    import ContentItemTable from '../../components/Spots/ContentItemTable.vue'

    export default {
        name: 'SpotsContentItems',
        title: 'Sites Spots Contenu IA',
        components: { ContentItemTable },
        computed: {
            spotId() {
                return parseInt(this.$route.params.id)
            },
            spotUrl() {
                return this.$route.query.url
            }
        }
    }
</script>